import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import InstitutionalText from 'src/components/sections/InstitutionalText'
import Newsletter from 'src/components/sections/Newsletter'
import Lgpd from 'src/components/common/Institutional/Lgpd'

const LGPD: FCC<PageProps> = (props) => {
  const {
    location: { host, pathname },
  } = props

  const title = 'LGPD'

  const docTitle = 'LGPD'

  const slug = 'institucionais/AntesCompra/lgpd'

  const description =
    'Encontre as melhores Lingeries e Produtos de Sex Shop Atacado e Varejo. Conheça a loja mais completa de produtos eróticos do Brasil.'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />

      {/* Sections */}

      <Breadcrumb
        name={docTitle}
        breadcrumbList={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      <InstitutionalText
        headerImage={{
          desktop: 'https://miess.vteximg.com.br/arquivos/lgpd-desktop.jpg',
          mobile: 'https://miess.vteximg.com.br/arquivos/lgpd-mobile.jpg',
          href: '/sex-shop',
          alt: 'Aproveite as vantagens exclusivas. Compre agora!',
        }}
      >
        <Lgpd />
      </InstitutionalText>
      <Newsletter />
    </>
  )
}

export default LGPD
