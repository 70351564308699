import { useMemo, useState } from 'react'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'
import { draftToHtml } from 'src/utils/draftToHtml'

import * as S from './styles'
import type { IPromoText } from '../Promotions/types'

const Card: FCC<IPromoText> = ({ title, content, slug }) => {
  const [show, setShow] = useState(false)

  const parsedText = useMemo(() => draftToHtml(content), [content])

  return (
    <S.Card show={show} onClick={() => setShow(!show)}>
      <h3>{title}</h3>
      <S.CardText className={slug} show={show}>
        {stringToSanitizedHtml(parsedText)}
      </S.CardText>
    </S.Card>
  )
}

export default Card
