// import { mobile } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const Card = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #361328;
  margin-top: 14px;
  padding-top: 4px;
  padding-bottom: 4px;

  .promocoes {
    span {
      background-color: #fb005d;

      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: underline;
      }
    }
  }

  .faq {
    a {
      color: ${({ theme }) => theme.colors.primary_0};
      font-weight: bold;
    }
  }

  h3 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 2%;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.grey_2};
    font-family: 'Baloo';
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: 'Baloo 2';
  }

  h3::after {
    content: '+';
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.grey_2};
    font-size: 28px;
    display: flex;
    align-items: center;
  }

  ${({ show }) =>
    show &&
    css`
      h3::after {
        content: '-';
      }
    `}
`

export const CardText = styled.div<{ show: boolean }>`
  max-height: 0;
  overflow: hidden;
  transition: 0.2s;
  padding: 5px 20px 0;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.grey_2};
  font-weight: 400;
  font-size: 0.9375rem;
  text-transform: unset;

  p {
    width: 100%;
    margin: 0px 0px 15px 0px;
    font-size: 15px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grey_2};
    font-weight: 400;
    font-size: 1rem;
    text-transform: unset;
  }

  ${({ show }) =>
    show &&
    css`
      max-height: 1000px;
      width: 100%;
      transition: 0.4s;
    `}
`
