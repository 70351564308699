import Card from '../Card'
import * as S from './styles'

const Lgpd: FCC = () => {
  const cmsInfo = [
    {
      title: '<h3>O que é a LGPD?</h3>',
      content:
        '<p><strong>A Lei Geral de Proteção de Dados Pessoais</strong> (LGPD ou LGPDP), Lei nº 13.709/2018, assegura o direito à privacidade e à proteção de dados pessoais dos usuários por meio de práticas transparentes e seguras.</p><p>Está em vigor desde 18/09/2020, e garante os direitos fundamentais de nossos clientes.</p><p>A <strong>MIESS MODA INTIMA ECOMMERCE LTDA</strong>, registrada sob o <strong>CNPJ 10.365.271/0001-02</strong>, respeita o sigilo das suas informações e garante que seus dados não serão disponibilizados, cedidos ou comercializados para terceiros.</p>',
    },
    {
      title: ' <h3>Quais dados são coletados pela Miess?</h3>',
      content:
        '<p>Em seu cadastro, são solicitados dados como:</p><p><strong> • E-mails e Telefones: </strong> são utilizados apenas para contatos referentes às compras realizadas em nosso site. Com prévia autorização dos clientes, enviamos e-mails informando as promoções vigentes em nossa loja, porém nossos clientes podem solicitar o não recebimento das ofertas quando desejarem. Não há o armazenamento de informações que não sejam necessárias para a efetivação da compra.</p><p><strong> • CPF: </strong>é exigido por lei para a emissão de notas fiscais e para o envio de mercadorias.</p><p><strong> • Senhas:</strong> são escolhidas e fornecidas pelo cliente no ato do cadastro em nosso site, são aquivadas em nosso banco de dados de maneira criptografada, permitindo que apenas o dono do cadastro tenha conhecimento.</p><p><strong> • Números de cartões de crédito: </strong>são registrados diretamente no banco de dados das administradoras de cartão, não permitindo o acesso a essas informações por parte do lojista. Ao informar os dados para a administradora, a mesma realiza a verificação da transação on-line e retorna apenas para informar se a compra foi liberada ou não.</p><p>Alguns outros dados podem ser solicitados na finalização de sua compra, mas reiteramos que os mesmos são usado apenas para fins de <strong>organização interna, conferência, monitoramento e controle</strong> de seu cadastro.</p>',
    },
    {
      title: '<h3>Como a Miess utiliza os seus dados pessoais?</h3>',
      content:
        '<p><strong>Dado pessoal</strong> é todo aquele relacionado à pessoa natural identificada ou identificável. Isso quer dizer: dados pessoais são todos aqueles que podem identificar uma pessoa – números, características pessoais, qualificação pessoal, etc. </p><p>Durante sua experiência em nossa loja, solicitamos alguns dados para <strong>viabilizar o procedimento da compra</strong>. Todas as informações fornecidas em nosso site são de uso exclusivo para personalização e facilitação da experiência de compra e não são disponibilizadas a terceiros. Os dados fornecidos são registrados em nosso banco de dados de forma automatizada e armazenados com total segurança sem a intervenção humana.</p>',
    },
    {
      title: '<h3>Cookies</h3>',
      content:
        '<p>São pequenos arquivos de texto simples, que contém informações que dependem diretamente do conteúdo do site visitado. A maioria dos sites armazenam informações dos visitantes assim como: preferências de idioma, cores ou, no caso de provedores de E-mail como Gmail ou Outlook, nomes e senhas. Os cookies expiram após um tempo, <strong>não são perigosos</strong> e nem podem ser visualizados por outros servidores. </p><p>A <strong>Miess é uma loja 100% segura</strong> e utiliza essa tecnologia apenas para proporcionar mais facilidade no momento de sua compra. O objetivo do Cookie é customizar ao máximo sua navegação no site, permitindo maior facilidade e agilidade no processo de compras.</p>',
    },
    {
      title: '<h3>Certificação</h3>',
      content: `<p>A nossa loja é munida com o <strong>Certificado de Segurança Go Daddy Secure Certification Authority</strong>, um dos certificados digitais mais modernos do mercado. Através dele, todas as informações transitadas pela internet são criptografadas. </p><p>Para garantir ainda mais segurança, ainda contamos com a certificação <strong> Let's Encrypt</strong>, a maior autoridade em segurança na Internet.`,
    },
  ]

  return (
    <S.Container>
      <h1>POLITICA DE PRIVACIDADE</h1>
      <h2>ENTENDA A LGPD: LEI GERAL DE PROTEÇÃO DE DADOS</h2>
      <div>
        {cmsInfo.map(({ title, content }, index) => (
          <Card key={index} title={title} content={content} />
        ))}
      </div>
    </S.Container>
  )
}

export default Lgpd
