import styled from 'styled-components'

export const Container = styled.div`
  /* margin-left: 5%; */

  h1 {
    line-height: 100%;
    margin: 4px 0px 20px 0px;
    color: ${({ theme }) => theme.colors.primary_0};
    font-family: 'Baloo';
    font-weight: 700;
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  h2 {
    margin: 17px 0px 7px 0px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.grey_2};
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-family: 'Baloo 2';
    text-decoration: underline;
  }

  a {
    color: ${({ theme }) => theme.colors.primary_0};
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
`
